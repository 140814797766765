@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

* {
  font-family: 'Roboto', sans-serif;
}

.Background {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-x: hidden;
}

.Rectangle {
  width: 30em;
  height: 25em;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  padding: 36px 41px 48px 47px;
  border-radius: 10px;
  background-color: #121826;
  text-align: center;
  margin: 0 auto;
}

.LogoStyle {
  width: 85px;
  height: 30px;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  display: block;
  margin-top: 1vh;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
}
.Title {
  height: 40px;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  /* margin: 27px 65px 89px 80px; */
  margin-top: 27px;
  margin-left: auto;
  margin-right: auto;
  /* font-family: SFProText; */
  font-size: 1.3em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.subTitle {
  width: 270px;
  height: 40px;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  margin-top: 1vh;
  margin-left: auto;
  margin-right: auto;
  /* font-family: SFProText; */
  font-size: 0.9em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #dcdce5;
}

.Button-primary {
  width: 312px;
  height: 50px;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 2vh;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 20px;
  background-color: #42e8e0;
  cursor: pointer;
  border: 0px;
}

.text-style {
  /* font-family: SFProText; */
  font-size: 1.2em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  color: #06071d;
}

.databaseSelected {
  width: 150px;
  height: 60px;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  margin: 14px 8px 35px 0;
  -webkit-align-items: center;
          align-items: center;
  padding: 5px 40px 1px 14px;
  border-radius: 3px;
  margin: 0 auto;
  background-color: #c7d4e9;
  text-align: center;
}

.databaseRendered {
  width: 150px;
  height: 60px;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  margin: 14px 8px 35px 0;
  -webkit-align-items: center;
          align-items: center;
  padding: 5px 40px 1px 14px;
  border-radius: 3px;
  margin: 0 auto;
  opacity: 0.9;
  background-color: #394150;
}

.imageDb {
  width: 108.9px;
  height: 50px;
  -webkit-flex-grow: 0;
          flex-grow: 0;
}

.selectedVersion {
  width: 59px;
  height: 40px;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  /* margin: 14px 4px 47px 0; */
  padding: 10px 22px 10px 21px;
  border-radius: 3px;
  background-color: #c7d4e9;
}

.unSelectedVersion {
  width: 60px;
  height: 40px;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  /* margin: 14px 4px 47px; */
  padding: 10px 23px 10px 21px;
  border-radius: 8px;
  background-color: #cecece;
}

.cluster-name:focus {
  border-bottom: 1px solid #5cfff3;
  transition: all 1s;
}
.ant-modal-header {
  background-color: #212936;
  border: none;
}
.ant-modal-title {
  color: white;
}
.ant-modal-footer {
  background-color: #212936;
  border: none;
}
.ant-modal-close {
  color: #cecece;
  opacity: 0.2;
}
.ant-divider {
  color: #2c2f34;
}

.avatarImage {
  border-radius: 8px;
  height: 90px;
  width: 90px;
}

.inputTitles {
  color: #738095;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Helvetica Neue, Lucida Grande, Segoe UI;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.backUpTitle {
  color: #ffffff;
  font-size: 1.3em;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Helvetica Neue, Lucida Grande, Segoe UI;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.dataTitles {
  color: #738095;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Helvetica Neue, Lucida Grande, Segoe UI;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.radioOptions {
  color: #ffffff;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Helvetica Neue, Lucida Grande, Segoe UI;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.saveChanges {
  color: #000000;
  background-color: rgba(66, 232, 224, 0.4);
  border: 0px;
  border-radius: 5px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Helvetica Neue, Lucida Grande, Segoe UI;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.saveChanges:hover {
  background-color: rgba(43, 63, 129, 0.4);
}

.settingsTitle {
  /* font-family: SFProText; */
  font-size: 2em;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.settingsButton {
  padding: 7px 128px 30px 9px;
  background-color: #283141;
  border: 0px;
}

.backupButton {
  padding: 7px 122px 30px 9px;
  background-color: #283141;
  border: 0px;
}

